import { INITIAL_SALESPERSON } from "app/utils/constant"

const initialState = {
  showEdit: false,
  showCreate: false,
  data: { ...INITIAL_SALESPERSON }
}
const SalespersonPageEditReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'SET_VALUE_SALESPERSON':
      return {
        ...state,
        ...action.payload
      }

    default: { return state }
  }
}

export default SalespersonPageEditReducer
