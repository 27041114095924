import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { INITIAL_ORDER } from 'app/utils/constant'
import { orderPaymentNCostCalculator, preciseRounding } from 'app/utils/calculation'

const initialState = {
    orderList: INITIAL_ORDER,
    salespersonList: [],
    costingItemList: [],
}

const SaleOrderSlice = createSlice({
    name: 'saleOrder',
    initialState,
    reducers: {
        addCostingItemList: (state, { payload }) => {
            state.costingItemList = payload
        },
        addOrderList: (state, { payload }) => {
            state.orderList = _.cloneDeep(payload)
        },
        addSalespersonList: (state, { payload }) => {
            state.salespersonList = payload
        },
        updateItemList: (state, { payload }) => {
            state.course =
                payload.value

            let count_title = [
                'quantity',
                'price',
                'discount'
            ]

            if (count_title.includes(payload.field)) {
                let { quantity, price, discount } =
                    state.orderList.course[payload.item_index]

                let total = (Number(quantity) || 0) * (Number(price) || 0)

                if (discount?.type === 1) {
                    total -= total * (Number(discount.amount) / 100)
                } else if (discount?.type === 2) {
                    total = total - (Number(discount.amount) || 0)
                }

                state.orderList.course[payload.item_index].total = total

                if (['discount'].includes(payload.field)) {
                    state.orderList.discount_amount = 0
                    state.orderList.discount = {}
                }
            }
        },
        updateSalespersonItem: (state, { payload }) => {
            let { type, item_index, value, salesperson_index } = payload
            state.orderList.course[item_index].salesperson[salesperson_index] = value

            if (state.orderList.course[item_index].salesperson.length <= 3) {
                if (salesperson_index == 0) {
                    if (!state.orderList.course[item_index].salesperson[1]) {
                        state.orderList.course[item_index].salesperson[1] = {}
                    }
                    if (!state.orderList.course[item_index].salesperson[2]) {
                        state.orderList.course[item_index].salesperson[2] = {}
                    }
                } else {
                    if (!state.orderList.course[item_index].salesperson[0]) {
                        state.orderList.course[item_index].salesperson[0] = {}
                    }
                }
            }
        },
        removeOrderItem: (state, { payload }) => {
            let { item, index_item } = payload
            _.remove(
                state.orderList.course,
                (item, index) => index == index_item
            )
        },
        updateSaleOrderPayment: (state, { payload }) => {
            state.orderList.payment = [...state.orderList.payment, payload]
        },
        removeSaleOrderPayment: (state, { payload }) => {
            _.remove(state.orderList.payment, (item, index) => index == payload)
        },
        updateSaleOrderMethods: (state, { payload }) => {
            let { payment, payment_index } = payload
            state.orderList.payment[payment_index] = payment
        },
        updateOrderParentKey: (state, { payload }) => {
            let { field, value } = payload

            if (field == 'payment') {
                state.orderList.payment = _.cloneDeep(value)
            } else if (field == 'costing_item') {
                state.orderList.costing_item = [...value]
            } else if (field == 'course') {
                state.orderList.course = { ...value }

            } else if (field === 'discount') {
                let discountAmount = 0
                let subTotal = state.orderList.subtotal
                if (Number(value.amount)) {
                    if (value.type === 1) {
                        discountAmount = subTotal * (Number(value.amount) / 100)
                    } else if (value.type === 2) {
                        discountAmount = Number(value.amount)
                    }
                }
                state.orderList.discount_amount = discountAmount
            } else {
                state.orderList[field] = value
            }
        },
        updateSaleOrderBalance: (state) => {
            let billingTotal = orderPaymentNCostCalculator(state.orderList.payment)
            let costTotal = orderPaymentNCostCalculator(state.orderList.costing_item)

            state.orderList.total = Number(billingTotal)
            state.orderList.costing = Number(costTotal)
            state.orderList.gp_value = Number(billingTotal) - Number(costTotal)
            if (Number(billingTotal)) {
                state.orderList.gp_percent = preciseRounding(state.orderList.gp_value / Number(billingTotal) * 100)
            } else {
                state.orderList.gp_percent = 0
            }
        },
    },
})

export const {
    addCostingItemList,
    generateOrder,
    addOrderList,
    updateItemList,
    updateSalespersonItem,
    removeOrderItem,
    updateSaleOrderPayment,
    removeSaleOrderPayment,
    updateSaleOrderMethods,
    updateOrderParentKey,
    updateSaleOrderBalance,
    addSalespersonList,
} = SaleOrderSlice.actions

export default SaleOrderSlice.reducer
