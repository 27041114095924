import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ClientList = Loadable(lazy(() => import('./ClientList')))

const clientRoutes = [
    {
        path: '/client/list',
        element: <ClientList />,
    },
]

export default clientRoutes
