import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const Appointment = Loadable(lazy(() => import('./Appointment')))

const appointmentRoutes = [
    {
        path: '/appointment',
        element: <Appointment />,
    }
]

export default appointmentRoutes
