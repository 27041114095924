import React, { createContext, useReducer, useEffect } from 'react'
import axios from 'axios.js'
import { api_url } from 'app/utils/constant'

export const SalespersonContext = createContext()
let merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))

const initialState = {
    business_type_list: [],
    salesperson_role_type: [],
    active_salesperson_role_data: null,
    adding_salesperson_data: {
        shop_id: merchantInfo?.id,
        name: '',
        permissions: [],
    },
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SALESPERSON_ROLE_TYPE_LIST':
            return {
                ...state,
                salesperson_role_type: action.payload,
            }

        case 'ACTIVE_SALESPERSON_DATA':
            return {
                ...state,
                active_salesperson_role_data: {
                    ...state.active_salesperson_role_data,
                    ...action.payload,
                },
            }

        case 'ADDING_SALESPERSON_DATA':
            return {
                ...state,
                adding_salesperson_data: {
                    ...state.adding_salesperson_data,
                    ...action.payload,
                },
            }

        case 'BUSINESS_TYPE':
            return {
                ...state,
                business_type_list: action.payload,
            }

        default:
            return state
    }
}

const SalespersonContextProvider = ({ children }) => {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
    const [salespersonState, salespersonContextDispatch] = useReducer(reducer, initialState)

    const handleRoleLoad = (async () => {
        await axios
            .get(`${api_url}role/`, {
                headers: {
                    Authorization: `token ${loginInfo?.accessToken}`,
                },
                params: {
                    shop_id: merchantInfo?.id,
                },
            })
            .then((data) => {
                salespersonContextDispatch({
                    type: 'SALESPERSON_ROLE_TYPE_LIST',
                    payload: data.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    })

    useEffect(() => {
        handleRoleLoad()
    }, [])


    return (
        <SalespersonContext.Provider value={[salespersonState, salespersonContextDispatch]}>
            {children}
        </SalespersonContext.Provider>
    )
}

export default SalespersonContextProvider
