import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const CourseList = Loadable(lazy(() => import('./CourseList')))

const courseRoutes = [
    {
        path: '/course/list',
        element: <CourseList />
    },
]

export default courseRoutes
