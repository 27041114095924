import {
    Badge,
    RecentActors,
    Assessment,
    CalendarMonth,
    MonetizationOn,
    Assignment,
    Dashboard,
    AccountBalance,
    Style,
    AccountBox
} from '@mui/icons-material'

export const ALL_NAVIGATION = [
    {
        name: 'navigations.dashboard',
        path: '/dashboard',
        icon: <Dashboard fontSize="medium" />,
        value: 'dashboard',
    },
    {
        name: 'navigations.client',
        path: '/client/list',
        icon: <RecentActors fontSize="medium" />,
        value: 'client',
    },
    {
        name: 'navigations.trainer',
        path: '/trainer/list',
        icon: <AccountBox fontSize="medium" />,
        value: 'trainer',
    },
    {
        name: 'navigations.course',
        path: '/course/list',
        icon: <Style fontSize="medium" />,
        value: 'course',
    },
    {
        name: 'navigations.salesperson',
        icon: <Badge fontSize="medium" />,
        children: [
            {
                name: 'navigations.salesperson_list',
                iconText: 'SI',
                path: '/salesperson/list',
                value: 'salesperson_salesperson_list',
            },
            {
                name: 'Salesperson Role',
                iconText: 'SI',
                path: '/salesperson/role',
                value: 'salesperson_salesperson_role',
            },
        ],
    },
    {
        name: 'navigations.quotation',
        icon: <Assignment fontSize="medium" />,
        children: [
            {
                name: 'navigations.quotation',
                iconText: 'SI',
                path: '/quotation/',
                value: 'quotation_quotation',
            },
            {
                name: 'navigations.quotation_history',
                iconText: 'SI',
                path: '/quotation/history',
                value: 'quotation_history',
            },
        ],
    },
    {
        name: 'navigations.sale_order',
        icon: <MonetizationOn fontSize="medium" />,
        children: [
            {
                name: 'navigations.sale_order',
                iconText: 'SI',
                path: '/sale_order/',
                value: 'sale_order_sale_order',
            },
            {
                name: 'navigations.sale_order_history',
                iconText: 'SI',
                path: '/sale_order/history',
                value: 'sale_order_history',
            },
            {
                name: 'navigations.costing_item',
                iconText: 'SI',
                path: '/sale_order/costing_item',
                value: 'sale_order_costing_item',
            },
        ],
    },
    {
        name: 'navigations.report',
        icon: <Assessment fontSize="medium" />,
        children: [
            {
                name: 'navigations.daily_report',
                iconText: 'SU',
                path: '/report/daily_report',
                value: 'report_daily_report',
            },
            {
                name: 'navigations.monthly_report',
                iconText: '404',
                path: '/report/monthly_report',
                value: 'report_monthly_report',
            }
        ],
    },
    {
        name: 'navigations.appointment',
        icon: <CalendarMonth fontSize="medium" />,
        path: '/appointment/',
        value: 'appointment_appointment',
    },
    {
        name: 'navigations.merchant_profile',
        icon: <AccountBalance fontSize="medium" />,
        path: '/merchant/profile',
        value: 'merchant',
    }
]
