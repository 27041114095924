import React, { lazy } from 'react'
import SalespersonRoleContext from './SalespersonRoleContext'
import Loadable from 'app/components/Loadable/Loadable'

const SalespersonList = Loadable(lazy(() => import('./SalespersonList')))
const SalespersonRole = Loadable(lazy(() => import('./SalespersonRole')))

const salespersonRoutes = [
    {
        path: '/salesperson/list',
        element: <SalespersonList />,
    },
    {
        path: '/salesperson/role',
        element: (
            <SalespersonRoleContext>
                <SalespersonRole />
            </SalespersonRoleContext>
        ),
    },
]

export default salespersonRoutes
