import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from 'app/hooks/useAuth'


const broadcastChannel = new BroadcastChannel('locker-channel');
const ActivityTracker = () => {

    const dispatch = useDispatch()
    const [elapsedDuration, setElapsedDuration] = useState(0)
    const merchantInfo = useSelector((e) => e.merchantInfo?.shop_information)
    const stateShopInfo = useSelector(
        (state) => state.merchantInfo?.shop_information
    )
    const inactiveTimePeriod =
        stateShopInfo?.inactive_time_period || merchantInfo?.inactive_time_period

    const lockedUser = () => {
        setElapsedDuration(0)
        broadcastChannel.postMessage({
            name: 'lock',
            path: window.location.href
        });
        dispatch({
            type: 'LOCKED_USER',
            payload: {},
        })
        localStorage.setItem('userLockStatus', true)
    }

    useEffect(() => {
        if (inactiveTimePeriod?.time_unit !== 'never') {
            let interval

            const resetTimer = () => {
                setElapsedDuration(0)
            }

            const startTimer = () => {
                resetTimer()
                interval = setInterval(() => {
                    setElapsedDuration((prevDuration) => prevDuration + 1)
                }, 1000) // Adjust the interval as per your requirement
            }

            const stopTimer = () => {
                clearInterval(interval)
            }

            const handleUserActivity = () => {
                if (interval) {
                    stopTimer()
                }
                startTimer()
            }

            startTimer()

            const handleVisibilityChange = () => {
                if (document.hidden) {
                    setElapsedDuration(0)
                    stopTimer()
                }
            };


            if (document.hidden) {
                setElapsedDuration(0)
                stopTimer()
            }


            document.addEventListener(
                'visibilitychange',
                handleVisibilityChange
            )
            document.addEventListener('mousemove', handleUserActivity)
            document.addEventListener('mouseover', handleUserActivity)
            document.addEventListener('touchstart', handleUserActivity)
            document.addEventListener('keydown', handleUserActivity)


            // Clean up the timer and event listeners on component unmount
            return () => {
                clearInterval(interval)
                document.removeEventListener(
                    'visibilitychange',
                    handleVisibilityChange
                )
                document.removeEventListener('mousemove', handleUserActivity)
                document.removeEventListener('mouseover', handleUserActivity)
                document.removeEventListener('touchstart', handleUserActivity)
                document.addEventListener('keydown', handleUserActivity)
            }
        }
    }, [inactiveTimePeriod])

    useEffect(() => {
        if (
            inactiveTimePeriod?.time_unit &&
            inactiveTimePeriod?.time_unit !== 'never'
        ) {
            if (
                inactiveTimePeriod.time_unit === 'second' &&
                inactiveTimePeriod.time_count < elapsedDuration
            ) {
                lockedUser()
            } else if (inactiveTimePeriod.time_unit === 'minute') {
                const minute = elapsedDuration / 60
                if (inactiveTimePeriod.time_count < minute) {
                    lockedUser()
                }
            } else if (inactiveTimePeriod.time_unit === 'hour') {
                const hour = elapsedDuration / 60 / 60
                if (inactiveTimePeriod.time_count < hour) {
                    lockedUser()
                }
            }
        }
    }, [elapsedDuration, inactiveTimePeriod])


    useEffect(() => {
        broadcastChannel.onmessage = (event) => {
            if (event?.data?.name === 'lock') {
                setElapsedDuration(0)
                dispatch({
                    type: 'LOCKED_USER',
                    payload: {},
                })
                localStorage.setItem('userLockStatus', true)

                if (document.hidden) {
                    window.location.href = event?.data?.path
                };
            } else if (event?.data?.name === 'unlock') {
                localStorage.setItem('userLockStatus', false)
                window.location.href = event?.data?.path
            }
        };
    }, [])

    return <></>
}

export default ActivityTracker
