import React from 'react'
import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import ActivityTracker from 'app/utils/ActivityTracker'
import Loadable from 'app/components/Loadable/Loadable'
import courseRoutes from 'app/views/course/CourseRoutes'
import reportRoutes from 'app/views/report/ReportRoutes'
import clientRoutes from 'app/views/client/ClientRoutes'
import trainerRoutes from 'app/views/trainer/TrainerRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import merchantRoutes from 'app/views/merchant/MerchantRoutes'
import MatxLayout from 'app/components/Theme/Layout/MatxLayout'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import quotationRoutes from 'app/views/quotation/QuotationRoutes'
import saleOrderRoutes from 'app/views/saleOrder/SaleOrderRoutes'
import staffRoutes from 'app/views/salesperson/SalespersonRoutes'
import appointmentRoutes from 'app/views/appointment/AppointmentRoutes'

const DefaultNavigate = Loadable(
    React.lazy(() => import('app/components/DefaultNavigate'))
)

const SaleOrderDoc = Loadable(
    React.lazy(() => import('app/views/saleOrder/SaleOrderPDF'))
)

const QuotationPDF = Loadable(
    React.lazy(() => import('app/views/quotation/QuotationPDF'))
)

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <ActivityTracker />
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [
                ...courseRoutes,
                ...staffRoutes,
                ...reportRoutes,
                ...clientRoutes,
                ...trainerRoutes,
                ...merchantRoutes,
                ...quotationRoutes,
                ...dashboardRoutes,
                ...saleOrderRoutes,
                ...appointmentRoutes,
            ],
        },
        ...sessionRoutes,

        {
            path: '/',
            element: <DefaultNavigate />,
        },
        {
            path: '/sale_order_pdf/:doc_no',
            element: <SaleOrderDoc />,
        },
        {
            path: '/sale_order_pdf/:doc_no/:type',
            element: <SaleOrderDoc />,
        },
        {
            path: '/quotation_doc/:doc_no',
            element: <QuotationPDF />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
