import { INITIAL_TRAINER } from "app/utils/constant"

const initialState = {
  showEdit: false,
  showCreate: false,
  data: INITIAL_TRAINER,
}
const TrainerPageEditReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'SET_VALUE_TRAINER':
      return { ...state, ...action.payload }

    default: { return state }
  }
}

export default TrainerPageEditReducer
