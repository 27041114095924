import React, { useState, forwardRef, useEffect, Fragment } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    Slide,
    IconButton,
    Stack,
    Icon,
    Typography,
    DialogContent,
} from '@mui/material'
import { styled } from '@mui/system'
import Palette from 'app/palette'
import { makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Title = styled(Stack)({
    color: 'white',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    fontSize: 20,
})

const Body = styled(DialogContent)({
    backgroundColor: 'white',
    padding: 20,
    color: Palette.darkest,
    minHeight: 80,
})

const Footer = styled(DialogActions)({
    backgroundColor: 'white',
    color: Palette.darker,
})

const ActionBtn = styled(Button)({
    fontWeight: 'bold',
    fontSize: 15,
})

const useStyles = makeStyles({
    title: {
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
})

const ConfirmationDialog = ({
    updateDialogStatus = () => { },
    open,
    dialogContent,
    cancelBtn = false,
    cancelAction = () => { },
    okAction = () => { },
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [iconName, setIconName] = useState('')
    const [content, setContent] = useState([])
    const [color, setColor] = useState(Palette.lightGray)

    useEffect(() => {
        // Setup the icon for each purpose
        if (dialogContent.isInfo) {
            setIconName('info')
            setColor(Palette.info)
        } else if (dialogContent.isWarning) {
            setIconName('report')
            setColor(Palette.lightRed)
        } else {
            setIconName('check_circle_outline')
            setColor(Palette.success)
        }
        if (!_.isEmpty(dialogContent.content)) {
            let temp = dialogContent.content.split('\n').map((line, index) => (
                <Fragment key={index}>
                    {line}
                    <br />
                </Fragment>
            ));
            setContent(temp)
        }
    }, [dialogContent])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={() => {
                updateDialogStatus()
            }}
            scroll="paper"
            maxWidth="xs"
            fullWidth
        >
            <Title
                direction={'row'}
                sx={{
                    borderColor: color,
                    backgroundColor: color,
                }}
            >
                <Stack direction={'row'} className={classes.title}>
                    {dialogContent.title}
                    <Icon sx={{ marginLeft: 1 }}>{iconName}</Icon>
                </Stack>
                <IconButton
                    onClick={() => {
                        updateDialogStatus()
                    }}
                >
                    <Close sx={{ color: 'white' }} />
                </IconButton>
            </Title>
            <Body>
                <Typography
                    variant="body1">
                    {content}
                </Typography>
            </Body>
            <Footer>
                {cancelBtn || dialogContent.cancelBtn && (
                    <ActionBtn
                        onClick={() => {
                            cancelAction()
                        }}
                        sx={{ color: Palette.warning }}
                    >
                        {t('main.cancel')}
                    </ActionBtn>
                )}
                <ActionBtn
                    onClick={() => {
                        okAction()
                    }}
                    sx={{ color: Palette.success }}
                >
                    {t('main.confirm')}
                </ActionBtn>
            </Footer>
        </Dialog>
    )
}

export default ConfirmationDialog
