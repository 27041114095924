import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const SaleOrder = Loadable(lazy(() => import('./SaleOrder')))
const History = Loadable(lazy(() => import('./History')))
const CostingItem = Loadable(lazy(() => import('./CostingItem')))
const SaleOrderPDF = Loadable(lazy(() => import('./SaleOrderPDF')))

const saleOrderRoutes = [
    {
        path: '/sale_order',
        element: <SaleOrder />,
    },
    {
        path: '/sale_order/costing_item',
        element: <CostingItem />,
    },
    {
        path: '/sale_order/history',
        element: <History />,
    },
    {
        path: '/sale_order_pdf',
        element: <SaleOrderPDF />,
    }
]

export default saleOrderRoutes
