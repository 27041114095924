import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const TrainerList = Loadable(lazy(() => import('./TrainerList')))

const trainerRoutes = [
    {
        path: '/trainer/list',
        element: <TrainerList />,
    },
]

export default trainerRoutes
