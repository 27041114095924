const initialState = {
  showEdit: false,
  showCreate: false,
  data: "",
  clientLabels: []
}
const ClientPageEditReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'SET_VALUE_CLIENT':
      return { ...state, ...action.payload }

    default: { return state }
  }
}

export default ClientPageEditReducer
