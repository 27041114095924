import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { INITIAL_QUOTATION } from 'app/utils/constant'
import { toRound, orderItemCalculator } from 'app/utils/calculation'

const initialState = {
    quotationList: INITIAL_QUOTATION,
    courseError: [],
    trainerList: []
}

const QuotationSlice = createSlice({
    name: 'quotation',
    initialState,
    reducers: {
        addQuotationList: (state, { payload }) => {
            state.quotationList = payload
        },
        updateTrainerList: (state, { payload }) => {
            state.trainerList = payload
        },
        updateItemError: (state, { payload }) => {
            state.courseError = payload
        },
        updateItemList: (state, { payload }) => {
            state.quotationList.course[payload.course_index][payload.field] =
                payload.value

            let count_title = [
                'quantity',
                'price',
                'discount',
            ]

            if (count_title.includes(payload.field)) {
                let { quantity, price, discount } =
                    state.quotationList.course[payload.course_index]

                let total = (Number(quantity) || 0) * (Number(price) || 0)

                if (discount?.type === 1) {
                    total -= total * (Number(discount.amount) / 100)
                } else if (discount?.type === 2) {
                    total = total - (Number(discount.amount) || 0)
                }

                state.quotationList.course[payload.course_index].total = total

                if (['discount'].includes(payload.field)) {
                    state.quotationList.discount_amount = 0
                    state.quotationList.discount = {}
                }
            }
        },
        removeQuotationItem: (state, { payload }) => {
            state.quotationList.course = {}
        },
        updatePayment: (state, { payload }) => {
            state.quotationList.payment = [...state.quotationList.payment, payload]
        },
        removePayment: (state, { payload }) => {
            _.remove(state.quotationList.payment, (item, index) => index == payload)
        },
        updatemMethods: (state, { payload }) => {
            let { payment, payment_index } = payload
            state.quotationList.payment[payment_index] = payment
        },
        updateQuotationParentKey: (state, { payload }) => {
            let { field, value } = payload

            state.quotationList[field] = value

            if (field === 'discount') {
                let discountAmount = 0
                let subTotal = state.quotationList.subtotal
                if (Number(value.amount)) {
                    if (value.type === 1) {
                        discountAmount = subTotal * (Number(value.amount) / 100)
                    } else if (value.type === 2) {
                        discountAmount = Number(value.amount)
                    }
                }

                state.quotationList.discount_amount = discountAmount
            }

            let billing_profile = ['client_profile', 'customer_profile']

            if (billing_profile.includes(field)) {
                let clone_course = _.cloneDeep(state.quotationList.course)
                let get_new_course_ = _.map(clone_course, (item) => {
                    let item_obj_total =
                        (Number(item.quantity) || 0) * (Number(item.price) || 0)

                    if (item.discount?.type === 1) {
                        item_obj_total =
                            item_obj_total -
                            item_obj_total *
                            (Number(item.discount.amount) / 100)
                    } else if (item.discount?.type === 2) {
                        item_obj_total =
                            item_obj_total - (Number(item.discount.amount) || 0)
                    }

                    return {
                        ...item,
                        total: item_obj_total,
                    }
                })

                state.quotationList.course = get_new_course_
            }
        },
        updateBalance: (state, { payload }) => {
            let itemTotal = orderItemCalculator(state.quotationList.course)
            let subTotal = itemTotal
            let tax = subTotal * 0.08
            let total = subTotal + tax

            state.quotationList.tax = tax
            state.quotationList.subtotal = subTotal
            state.quotationList.total = Number(total)
            state.quotationList.balance = Number(total)
        },
    },
})

export const {
    updateTrainerList,
    updateItemError,
    addQuotationList,
    updateItemList,
    removeQuotationItem,
    updatePayment,
    removePayment,
    updatemMethods,
    updateQuotationParentKey,
    updateBalance,
} = QuotationSlice.actions

export default QuotationSlice.reducer
