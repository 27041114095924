
const initialState = {
    courseList: [],
    courseLabels: []
}
const CourseReducer = function (state = initialState, action) {
    switch (action.type) {
        case 'FETCH_COURSE_TAGS': {
            return {
                ...state,
                courseLabels: [...action.payload]
            };
        }
        case 'ADD_NEW_TAG': {
            return {
                ...state,
                courseLabels: [
                    ...state.courseLabels,
                    action.payload
                ]
            };
        }
        case 'REMOVE_TAG': {
            return {
                ...state,
                courseLabels: state.courseLabels.filter(tag => tag.id !== action.payload)
            };
        }
        case 'REMOVE_TAG_FROM_COURSE': {
            return {
                ...state,
            };
        }
        default: {
            return state;
        }
    }
};

export default CourseReducer;
