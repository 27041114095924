import {
    TextField,
    Autocomplete,
    Switch,
    Checkbox,
    Tab,
    Box,
    SnackbarContent,
} from '@mui/material'
import { styled } from '@mui/system'
import { withStyles } from '@mui/styles'
import { DateCalendar, DateField } from '@mui/x-date-pickers'
import { TextValidator } from 'react-material-ui-form-validator'
import Palette from 'app/palette'
import { themeShadows } from 'app/components/Theme/themeColors'

export const CustomTextField = withStyles({
    root: {
        '& .MuiFormLabel-root': {
            color: Palette.darkest,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
            borderRadius: 5,
            '& fieldset': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            '& input': {
                backgroundColor: 'white',
                color: Palette.darkest,
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
        '& .MuiOutlinedInput-input.Mui-disabled': {
            '-webkit-text-fill-color': Palette.darkest,
            '&:hover': {
                borderColor: Palette.lightGray,
                cursor: 'pointer',
            },
            color: Palette.darkest,
            borderRadius: 5,
            borderColor: Palette.lightGray,
            backgroundColor: Palette.lightGray,
            border: '1px solid',
            '&.Mui-focused fieldset': {
                borderColor: Palette.lightGray,
            },

        },
        '& .MuiOutlinedInput-input': {
            '& MuiInputBase-inputMultiline': {
                color: Palette.darkest,
            },
            '-webkit-text-fill-color': Palette.darkest,
            color: Palette.darkest,
        },

        '& label.Mui-focused': {
            color: Palette.darkest,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },


    },
})(TextField)


export const CustomTextValidator = withStyles({
    root: {
        '& .MuiFormLabel-root': {
            color: Palette.darkest,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            '& input': {
                fontSize: 16,
                color: Palette.darkest,
            },
        },
    },
})(TextValidator)

export const CustomDarkTextField = withStyles({
    root: {
        border: '0px',
        // borderRadius: 100,

        '& .MuiFormLabel-root': {
            color: Palette.darkest,
        },
        '& .MuiOutlinedInput-input.Mui-disabled': {
            '-webkit-text-fill-color': Palette.darkest,
            backgroundColor: Palette.backgroundColor,
            borderRadius: 5,
            '&:hover': {
                cursor: 'pointer',
            },
        },
        '& .MuiOutlinedInput-input': {
            '& MuiInputBase-inputMultiline': {
                color: Palette.darkest,
            },
            '-webkit-text-fill-color': Palette.darkest,
            color: Palette.darkest,
        },

        '& label.Mui-focused': {
            color: Palette.darkest,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: Palette.backgroundColor,
        },
        '& .MuiInputBase-multiline': {
            backgroundColor: 'white',
            borderColor: Palette.backgroundColor,
            color: Palette.darkest,
            '&:hover fieldset': {
                border: '1px solid',
                borderColor: Palette.backgroundColor,
            },
            '&.Mui-focused fieldset': {
                border: '1px solid',
                borderColor: Palette.backgroundColor,
            },
        },
        '& .MuiOutlinedInput-root .MuiInputBase-multiline': {
            '& fieldset': {
                color: Palette.darkest,
            },
            '& input': {
                backgroundColor: Palette.backgroundColor,
                borderColor: Palette.backgroundColor,
                color: Palette.darkest,
            },
            '&:hover fieldset': {
                borderColor: 'white',
                border: '0px',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                border: '0px',
            },
            '&.Mui-disabled fieldset': {
                borderColor: Palette.gray,
                border: '1px solid',
                cursor: 'pointer',
            },
        },
    },
})(TextField)


export const CustomDarkDateField = withStyles({
    root: {
        border: '0px',
        borderRadius: 100,

        '& .MuiFormLabel-root': {
            color: Palette.darkest,
        },
        '& .MuiOutlinedInput-input.Mui-disabled': {
            '-webkit-text-fill-color': Palette.darkest,
            backgroundColor: Palette.backgroundColor,
            borderRadius: 5,
            '&:hover': {
                cursor: 'pointer',
            },
        },
        '& .MuiOutlinedInput-input': {
            '& MuiInputBase-inputMultiline': {
                color: Palette.darkest,
            },
            '-webkit-text-fill-color': Palette.darkest,
            color: Palette.darkest,
        },

        '& label.Mui-focused': {
            color: Palette.darkest,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: Palette.backgroundColor,
        },
        '& .MuiInputBase-multiline': {
            backgroundColor: Palette.backgroundColor,
            borderColor: Palette.backgroundColor,
            color: Palette.darkest,
            '&:hover fieldset': {
                border: '1px solid',
                borderColor: Palette.backgroundColor,
            },
            '&.Mui-focused fieldset': {
                border: '1px solid',
                borderColor: Palette.backgroundColor,
            },
        },
        '& .MuiOutlinedInput-root .MuiInputBase-multiline': {
            '& fieldset': {
                color: Palette.darkest,
            },
            '& input': {
                backgroundColor: Palette.backgroundColor,
                borderColor: Palette.backgroundColor,
                color: Palette.darkest,
            },
            '&:hover fieldset': {
                borderColor: 'white',
                border: '0px',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                border: '0px',
            },
            '&.Mui-disabled fieldset': {
                borderColor: Palette.gray,
                border: '1px solid',
                cursor: 'pointer',
            },
        },
    },
})(DateField)

export const CustomAutocompleteField = withStyles({
    root: {
        backgroundColor: 'white',
        '& .MuiSelect-select:hover': {
            borderColor: 'white',
            color: Palette.darkest,
            outline: '1px red solid',
        },
        '& .MuiSelect-root': {
            borderColor: 'white',
            color: Palette.darkest,
            outline: '1px red solid',

            ':hover': {
                borderColor: 'white',
                color: Palette.darkest,
            },
        },
        '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
            '& fieldset': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            '&:hover fieldset': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            '& input': {
                backgroundColor: 'white',
                borderColor: 'white',
                color: Palette.darkest,
            },
            ':focused': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            ':label': {
                borderColor: 'white',
                color: Palette.darkest,
            },
        },
    },
})(Autocomplete)

export const DropdownTextField = withStyles({
    root: {
        '& .MuiFormHelperText-root.Mui-error': {
            backgroundColor: '#f6f9fc',
            paddingTop: '3px',
            paddingRight: '14px',
            paddingLeft: '14px',
            margin: 'inherit',
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF3D57',
        },
    },
})(CustomTextField)

export const CustomDarkAutocompleteField = withStyles({
    root: {
        backgroundColor: Palette.backgroundColor,
        boxShadow: themeShadows[6],
        color: Palette.darkest,
        "& .MuiAutocomplete-inputRoot.Mui-disabled": {
            backgroundColor: Palette.backgroundColor,
            color: Palette.backgroundColor,

        },
        "& .MuiInputBase-input.Mui-disabled": {
            backgroundColor: Palette.backgroundColor,
        },
        '& .MuiSelect-select:hover': {
            borderColor: 'white',
            color: Palette.darkest,
            outline: '1px red solid',
        },
        '& .MuiSelect-root': {
            borderColor: 'white',
            color: Palette.darkest,
            outline: '1px red solid',
            ':hover': {
                borderColor: 'white',
                color: Palette.darkest,
            },
        },
        '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
            '& fieldset': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            '&:hover fieldset': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            '& input': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            ':focused': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            ':label': {
                borderColor: 'white',
                color: Palette.darkest,
            },
            '& disabled': {
                borderColor: 'white',
                color: Palette.darkest,
            },
        },
    },
})(Autocomplete)

export const CustomDateCalendar = withStyles({
    root: {
        color: 'black',
        '& .MuiDayCalendar-weekDayLabel': {
            color: 'black',
        },
        '& .MuiPickersDay-root': {
            color: 'black',
            '&.Mui-selected': {
                backgroundColor: Palette.theme,
                color: 'white',
            },
        },
        '& .MuiPickersMonth-root ': {
            '&.Mui-selected': {
                backgroundColor: Palette.theme,
                color: 'white',
            },
        },
        '& .MuiPickersYear-yearButton': {
            '&.Mui-selected': {
                backgroundColor: Palette.theme,
                color: 'white',
            },
        },
    },
})(DateCalendar)

export const CustomSwitch = withStyles({
    root: {
        '& .MuiSwitch-switchBase .MuiSwitch-thumb ': {
            color: 'white',
        },
        '& .Mui-checked+.MuiSwitch-track': {
            backgroundColor: Palette.theme,
        },
    },
})(Switch)

export const CustomCheckbox = withStyles({
    root: {
        color: Palette.darkest,
        marginLeft: 15,
        '&.Mui-checked': {
            color: Palette.darkest,
        },
        '&.Mui-disabled': {
            color: Palette.darkest,
            pointerEvents: 'auto',
        },
        '&.MuiCheckbox-indeterminate': {
            color: Palette.darkest,
        },
    },
})(Checkbox)

export const CustomTab = withStyles({
    root: {
        color: Palette.darkest,
        '&.Mui-selected': {
            backgroundColor: Palette.theme,
            color: 'white',
            borderRadius: 3,
        },
    },
})(Tab)

export const CustomTabMenu = withStyles({
    root: {
        color: Palette.darkest,
        '&.Mui-selected': {
            color: Palette.theme,
        },
    },
})(Tab)

export const LoadingContainer = styled(Box)({
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '2 !important',
    width: '100%',
    height: '100% !important',
    background: 'rgba(0,0,5,0.1)',
    opacity: '1',
})

export const CustomSnackbarContent = withStyles({
    root: {
        backgroundColor: Palette.theme
    },
})(SnackbarContent)
