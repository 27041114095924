import React from 'react';
import { Button, Snackbar, Typography } from '@mui/material';
import Palette from 'app/palette'
import { CustomSnackbarContent } from 'app/utils/customStyle'

const NotificationBar = ({ open, handleUpdate = () => { } }) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={open}
        >
            <CustomSnackbarContent
                message="A new update is available! Click to reload."
                action={
                    <Button variant="contained" onClick={handleUpdate}>
                        <Typography sx={{ color: Palette.success, fontWeight: 'bold' }}>Update</Typography>
                    </Button>
                }
            />
        </Snackbar>
    );
}

export default NotificationBar