import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const NotFound = Loadable(lazy(() => import('./NotFound')))
const DashboardLogin = Loadable(lazy(() => import('./DashboardLogin')))

const sessionRoutes = [
    {
        path: 'dashboard_login',
        element: <DashboardLogin />,
    },
    {
        path: '/session/404',
        element: <NotFound />,
    },
]

export default sessionRoutes
