const Palette = {
    danger: 'red',
    warning: '#ff9800',
    success: 'mediumseagreen',
    info: 'deepskyblue',
    lightGray: '#ececec',
    dark: '#3B5369',
    green: '#00a65a',
    blue: '#228AD0',
    dodgerblue: 'dodgerblue',
    theme: '#FF0000',
    superLightTheme: '#026773',
    lightTheme: '#FFA0A0',
    darkTheme: '#012E40',
    red: 'red',
    lightRed: '#F69697',
    light: '#E1F2FC',
    gray: '#C7D2D9',
    darkerGray: '#ADBDC9',
    darker: '#173540',
    darkest: '#161F30',
    grayFont: '#61727C',
    backgroundColor: '#f6f9fc',
    fontColor: '#63656e',
    yellow: '#ffc107',
    chartLabel: '#2a2f52',
    btnBackground: 'linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)'
}

export default Palette
