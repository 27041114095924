import { combineReducers } from 'redux'
import CourseReducer from './CourseReducer'
import TrainerReducer from './TrainerReducer'
import Appointment from '../slice/Appointment'
import NavigationReducer from './NavigationReducer'
import GlobalDataReducer from './GlobalDataReducer'
import QuotationSlice from '../slice/QuotationSlice'
import SaleOrderSlice from '../slice/SaleOrderSlice'
import ClientPageEditReducer from './ClientPageEditReduces'
import TrainerPageEditReducer from './TrainerPageEditReduces'
import SalespersonPageEditReducer from './SalespersonPageEditReducer'

const RootReducer = combineReducers({
    appointment: Appointment,
    saleOrder: SaleOrderSlice,
    quotation: QuotationSlice,
    courseData: CourseReducer,
    trainerData: TrainerReducer,
    globalData: GlobalDataReducer,
    navigations: NavigationReducer,
    clientPageEdit: ClientPageEditReducer,
    trainerPageEdit: TrainerPageEditReducer,
    salespersonPageEdit: SalespersonPageEditReducer,
})

export default RootReducer
