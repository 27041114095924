import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const MerchantProfile = Loadable(lazy(() => import('./MerchantProfile')))

const merchantRoutes = [
    {
        path: '/merchant/profile',
        element: <MerchantProfile />
    },
]

export default merchantRoutes
