import Decimal from 'decimal.js'
import _ from 'lodash'

const merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))

export const preciseRounding = (price, separator = true, decimal = 2) => {
    // This roundingb function created is to handle such cases like 40.425
    // normal function will return 40.42 the actual value should be 40.43
    // 74.305 will be 74.31
    // This solution doesn't work when value is 0.155
    // return Number(price).toFixed(2);
    // 100.305 will be 100.31
    // This function doesn't work when you are trying to rounding for this value 40.425
    // Number(Math.round(price * 100) / 100).toFixed(2);
    let decimalValue = 0
    let digits = 5
    price = mul([price, parseFloat(Math.pow(10, digits + 1))])
    decimalValue = parseInt(Math.floor(price)) - Math.floor(price / 10) * 10
    price = Math.floor(price / 10)
    if (decimalValue >= 5) {
        price += 1
    }
    price /= parseFloat(Math.pow(10, digits))

    price = Decimal(price).toFixed(decimal)

    if (separator) {
        return thousandSeparator(price)
    }

    return price
}

const mul = (numbers) => {
    const result = numbers.reduce((x, y) => {
        return x.mul(new Decimal(Number(y)).times(100)).div(100)
    }, new Decimal(1))
    return result.valueOf()
}

export const thousandSeparator = (num) => {

    if ([null, undefined].includes(num)) return num

    var num_parts = num.toString().split('.')
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return num_parts.join('.')
}

export const numberFieldCorrector = (value) => {
    if (value.charAt(1) != '') {
        if (value.charAt(0) == '0' && value.charAt(1) != '.') {
            value = value * 1
        }
    }
    return value
}

export const orderCalculator = (order) => {
    let totalAmount = orderItemCalculator(order.course)

    let subtotal = totalAmount
    let payment_amount = paymentCalculator(order.payment)
    let tax = taxCalculator(subtotal) // exclude the outstanding invoice payment
    let total = Number(subtotal) + Number(tax)
    let discount_amount = discountValueCalculator(
        total,
        order.discount
    )

    total -= discount_amount

    let balance = total - payment_amount

    return {
        subtotal,
        discount_amount,
        tax,
        total,
        payment_amount,
        balance,
    }
}

export const orderItemCalculator = (itemList, key) => {
    let totalAmount = 0
    _.map(itemList, (item) => {
        let itemTotal = Number(item.quantity || 0) * Number(item.unit_price || 0)

        let discount_value = discountValueCalculator(
            itemTotal,
            item.discount
        )
        totalAmount += itemTotal - discount_value
    })
    return totalAmount
}

export const orderPaymentNCostCalculator = (itemList, key) => {
    let totalAmount = 0
    _.map(itemList, (item) => {
        let itemTotal = (Number(item.quantity || 0) * Number(item.unit_price || 0)) * (1 + Number(item.tax || 0) / 100)

        totalAmount += itemTotal
    })
    return totalAmount
}

export const discountValueCalculator = (targetAmount, discountObj) => {
    let discount_value = 0
    if (!_.isEmpty(discountObj)) {
        if (discountObj.type == 1) {
            return Number(
                preciseRounding(
                    (Number(targetAmount) * Number(discountObj.amount)) / 100,
                    false
                )
            )
        } else {
            return Number(discountObj.amount) || 0
        }
    }
    return discount_value
}


export const taxCalculator = (subtotal) => {
    let taxAmount = Number(subtotal)

    taxAmount *= 8
    return preciseRounding(taxAmount, false)
}

export const paymentCalculator = (paymentList) => {
    let paymentTotal = 0
    !_.isEmpty(paymentList) &&
        paymentList.map((el) => {
            if (Number(el.amount)) {
                paymentTotal += Number(el.amount)
            }
        })

    return paymentTotal
}

export const dayNumberCalculator = (date1, date2) => {
    date1 = new Date(date1)
    date2 = new Date(date2)

    // To calculate the no. of days between two dates
    let day_difference = Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24)) + 1

    return day_difference
}

export const toRound = (total) => {
    let minus = 1
    if (Number(total) < 0) {
        minus = -1
        total = String((Number(total) * minus).toFixed(2))
    }

    let calculatedTotal = 0
    total = String(Number(total).toFixed(2))
    var decimalDigit = total.substr(total.length - 2) / 100
    var lastDigit = total.substr(total.length - 1)
    if (0 < Number(lastDigit) && Number(lastDigit) < 3) {
        decimalDigit -= Number(lastDigit / 100)
    } else if (2 < Number(lastDigit) && Number(lastDigit) < 5) {
        decimalDigit += (5 - Number(lastDigit)) / 100
    } else if (5 < Number(lastDigit) && Number(lastDigit) < 8) {
        decimalDigit -= Number(lastDigit / 100) - 5 / 100
    } else if (7 < Number(lastDigit) && Number(lastDigit) <= 10) {
        decimalDigit += (10 - Number(lastDigit)) / 100
    }
    calculatedTotal = Math.floor(total) + Number(decimalDigit)
    if (minus == -1) {
        calculatedTotal = String(Number(calculatedTotal) * minus)
    }

    return preciseRounding(calculatedTotal, false)
}


export const getDateRangeForWeek = (weekNumber, year) => {
    // Calculate the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);

    // Calculate the first day of the first week (may not be Monday)
    const daysToMonday = (7 - firstDayOfYear.getDay() + 1) % 7;
    const firstDayOfWeekOne = new Date(year, 0, 1 + daysToMonday);

    // Calculate the start date of the desired week
    const startDate = new Date(firstDayOfWeekOne);
    startDate.setDate(firstDayOfWeekOne.getDate() + (weekNumber - 1) * 7);

    // Calculate the end date of the week (Sunday)
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    return { startDate, endDate };
}

export const getCurrentWeekNumber = () => {
    const today = new Date()
    const startOfYear = new Date(today.getFullYear(), 0, 1)
    const daysSinceStartOfYear = Math.floor(
        (today - startOfYear) / (24 * 60 * 60 * 1000)
    );

    // Calculate the current week number by dividing the days by 7 and rounding up.
    const weekNumber = Math.ceil((daysSinceStartOfYear) / 7)
    return weekNumber
}
