
const initialState = {
    trainerList: [],
    trainerLabels: []
}
const TrainerReducer = function (state = initialState, action) {
    switch (action.type) {
        default: {
            return state;
        }
    }
};

export default TrainerReducer;
