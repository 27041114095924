import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const DailyReport = Loadable(lazy(() => import('./DailyReport')))
const MonthlyReport = Loadable(lazy(() => import('./MonthlyReport')))

const reportRoutes = [
    {
        path: '/report/daily_report',
        element: <DailyReport />
    },
    {
        path: '/report/monthly_report',
        element: <MonthlyReport />
    },
]

export default reportRoutes
