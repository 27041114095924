import moment from 'moment'
import _ from 'lodash'

export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const PORTFOLIO_IMAGE_COUNT = 13


const endpoint_url = 'https://release-iconic-api.ten-binary.com/' //release
// const endpoint_url = 'http://127.0.0.1:8000/' //localhost

export const websocket_url = 'wss://release-iconic-api.ten-binary.com' //release
// export const websocket_url = 'ws://127.0.0.1:8000'

export const api_url = endpoint_url + 'api/'
export const api_url_unauth = endpoint_url

const activeUser = JSON.parse(localStorage.getItem('activeUser'))

export const DIALOG_TEMPLATE = {
    title: '',
    content: '',
    isWarning: false,
    isInfo: false,
    isSuccess: false,
}

export const INITIAL_TRAINER = {
    id: '',
    name: '',
    contact: '',
    profile: '',
    email: '',
    certificate: [],
    ttt_cert_no: '',
    trainer_fee: '',
    trainer_course: [],
    updated_by: activeUser || '',
    remark: '',
}

export const INITIAL_TRAINER_FORM_ERROR = {
    name: '',
    contact: '',
    email: '',
    remark: '',
    profile: '',
    certificate: '',
    trainer_fee: '',
    ttt_cert_no: '',
    trainer_course: ''
}

export const INITIAL_SALESPERSON = {
    id: '',
    user: {
        id: '',
        username: '',
        password: '',
        confirm_password: '',
        role: ''
    },
    name: '',
    contact: '',
    address_1: '',
    address_2: '',
    dob: null,
    email: '',
    role: '',
    gender: '',
    designation: '',
    yearly_target: '',
    portfolio_images: [],
    updated_by: activeUser || null,
}

export const INITIAL_SALESPERSON_FORM_ERROR = {
    id: '',
    user: {
        id: '',
        username: '',
        password: '',
        confirm_password: '',
        access_code: '',
    },
    name: '',
    contact: '',
    address_1: '',
    address_2: '',
    dob: null,
    email: '',
    gender: '',
    access_code: '',
    designation: '',
    yearly_target: '',
    effective_date: new Date(),
    portfolio_images: [],
    deactivate_date: null,
    updated_by: activeUser || null,
    image_url: '',
    reviewCount: '',
    remark: '',
}

export const INITIAL_POSITION_FORM_ERROR = {
    id: '',
    name: '',
    selectedItem: '',
}

export const INITIAL_ORDER = {
    id: '',
    prepared_by: {},
    date: '',
    so_date: new Date().toString(), // For the document date
    doc_date: moment(new Date()).format('YYYYMMDD').toString(), // For the document number purpose
    client_profile: {},
    pic: [{}, {}, {}],
    course: {
        start_date: '', end_date: '', start_time: '09:00', end_time: '17:00', version: '',
        program_type: '', no_of_participant: '', no_of_participant_gov: '', venue: '',
        manual_pick_up_date: '', po_no: '', trainer_colead: '', trainer_lead: '',
        quantity: 1, unit_price: 0, course: {}
    },
    version: '',
    program_type: '',
    trainer_lead: {},
    trainer_colead: {},
    trainer_remark: '',
    no_of_participant: '',
    no_of_participant_gov: '',
    payment: [
        { id: _.uniqueId('payment_'), name: 'Program Fee', unit_price: '', tax: '', quantity: '', remark: '' },
        { id: _.uniqueId('payment_'), name: 'Hotel/Venue', unit_price: '', tax: '', quantity: '', remark: '' },
        { id: _.uniqueId('payment_'), name: 'Transportation', unit_price: '', tax: '', quantity: '', remark: '' },
        { id: _.uniqueId('payment_'), name: 'F&B', unit_price: '', tax: '', quantity: '', remark: '' }],
    start_date: '',
    end_data: '',
    start_time: '',
    end_time: '',
    training_venue: '',
    manual_pick_up_date: '',
    billing_company: '',
    billing_address: '',
    billing_city: '',
    billing_state: '',
    billing_pic: '',
    billing_pic_email: '',
    billing_remark: '',
    costing_item: [],
    revenue_total: 0,
    cost_total: 0,
    balance: 0,
    tax: 0,
    term: '',
    gross_profit: 0,
    so_remark: ''
}

export const INITIAL_ORDER_ERROR = {
    prepared_by: '',
    so_date: '',
    client_profile: {},
    pic: [{}, {}, {}],
    course: {
        start_date: '', end_date: '', start_time: '', end_time: '', version: '',
        program_type: '', no_of_participant: '', no_of_participant_gov: '', venue: '',
        manual_pick_up_date: '', po_no: '', trainer_colead: '', trainer_lead: '',
        course: {}
    },
    version: '',
    program_type: '',
    trainer_lead: '',
    no_of_participant: '',
    no_of_participant_gov: '',
    payment: [],
    start_date: '',
    end_data: '',
    start_time: '',
    end_time: '',
    training_venue: '',
    bill_listing: [],
    billing_company: '',
    billing_address: '',
    billing_city: '',
    billing_state: '',
    billing_pic: '',
    billing_pic_email: '',
    costing_item: [],
    revenue_total: 0,
    cost_total: 0,
    balance: 0,
    tax: 0,
    term: '',
    gross_profit: 0,
    so_remark: ''
}

export const INITIAL_QUOTATION = {
    id: '',
    client_profile: {},
    date: moment(new Date()).format('YYYY-MM-DD'), // For the document date
    doc_date: moment(new Date()).format('YYYY-MM-DD'), // For the document number purpose
    prepared_by: {},
    valid_until: '',
    pic: {},
    term: '',
    so_no: '',
    course: [],
    subtotal: '',
    total: '',
    discount_amount: '',
    discount: {},
    balance: '',
    tax: '',
    type: 'Quotation',
    updated_by: activeUser || null,
}

export const INITIAL_REMARK = {
    id: '',
    title: '',
    content: '',
    client_profile: '',
    date: new Date(),
    updated_by: activeUser || null,
}

export const INITIAL_CLIENT_FORM_ERROR = {
    company_name: '',
    tax_no: '',
    office_contact: '',
    email: '',
    website: '',
    address: '',
    city: '',
    handled_by: '',
    state: '',
}

export const INITIAL_CLIENT = {
    id: '',
    company_name: '',
    tax_no: '',
    office_contact: '',
    email: '',
    website: '',
    address: '',
    city: '',
    state: '',
    updated_by: activeUser || null,
}

export const INITIAL_MERCHANT = {
    id: '',
    company_name: '',
    business_registration_no: '',
    tax_no: '',
    office_contact: '',
    company_email: '',
    website: '',
    taxable: false,
    tax_inclusive: false,
    address_1: '',
    address_2: '',
    name: '',
    contact: '',
    email: '',
    updated_by: activeUser || null,
    user: {
        id: '',
        username: '',
        name: '',
        email: '',
        password: '',
        confirm_password: '',
    },
    merchant_config: {
        company_name: '',
        business_registration_no: '',
        tax_no: '',
        office_contact: '',
        company_email: '',
        website: '',
        taxable: false,
        tax_inclusive: false,
        address_1: '',
        address_2: '',
    }
}

export const INITIAL_MERCHANT_FORM_ERROR = {
    id: '',
    company_name: '',
    business_registration_no: '',
    tax_no: '',
    office_contact: '',
    company_email: '',
    website: '',
    taxable: '',
    tax_inclusive: '',
    address_1: '',
    address_2: '',
    name: '',
    contact: '',
    email: '',
    user: {
        id: '',
        username: '',
        name: '',
        email: '',
        password: '',
        confirm_password: '',
    }
}

export const INITIAL_APPOINTMENT = {
    id: '',
    client: '',
    date: new Date(),
    start: new Date(),
    end: new Date(),
    pic: '',
    salesperson: '',
    agenda: '',
    updated_by: activeUser || null,
}

export const INITIAL_APPOINTMENT_FORM_ERROR = {
    id: '',
    client: '',
    date: '',
    start: '',
    end: '',
    pic: '',
    salesperson: '',
    agenda: '',
}

// parent menu name
export const PARENT_NAVIGATIONS_LIST = [
    'appointment',
    'sale_order',
    'salesperson',
    'client',
    'course',
    'trainer',
    'dashboard',
    'merchant_profile',
    'quotation',
    'report',
]

// sub menu name
// to wirte a sub menu name parent_sbumenuname
export const ALL_NAVIGATION_PERMISSION_LIST = [
    'salesperson_salesperson_list',
    'salesperson_salesperson_role',
    'appointment_appointment',
    'client',
    'course',
    'dashboard',
    'merchant_profile',
    'quotation_history',
    'quotation_quotation',
    'report_daily_report',
    'report_monthly_report',
    'sale_order_history',
    'sale_order_sale_order',
    'sale_order_costing_item',
    'trainer',
]

export const INITIAL_PIC = {
    id: '',
    name: '',
    contact_no: '',
    extension_no: '',
    designation: '',
    email: '',
    remark: '',
    updated_by: ''
}

export const INITIAL_ACTION_FORM = {
    id: '',
    pic: '',
    title: '',
    content: '',
    updated_by: '',
    date: new Date().toJSON().slice(0, 10),
}

export const INITIAL_DESIGNATION = {
    id: '',
    name: '',
    updated_by: activeUser || null,
}

export const INITIAL_DESIGNATION_FORM_ERROR = {
    id: '',
    name: '',
}

export const INITIAL_COURSE = {
    id: '',
    title: '',
    description: '',
    trainer_course: [],
    updated_by: activeUser || null,
}

export const INITIAL_COURSE_FORM_ERROR = {
    title: '',
    description: '',
    trainer_course: []
}

export const INITIAL_COURSE_TRAINER = {
    id: '',
    trainer: {},
    doc_url: '',
    doc_type: '',
    doc_name: '',
    deleted_doc: '',
    is_active: true,
    info: '',
    price: '',
}

export const INITIAL_COURSE_TRAINER_FORM_ERROR = {
    trainer: '',
    title: '',
    doc_url: '',
    info: '',
    price: '',
}

export const INITIAL_TITLE = {
    id: '',
    name: '',
    updated_by: activeUser || null,
}

export const INITIAL_TITLE_FORM_ERROR = {
    id: '',
    name: '',
}